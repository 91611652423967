/*
 * LOAD DEPENDENCIES
 * --------------------------------------------------------------------------------
 */
import { createRouter, createWebHistory } from 'vue-router'
import Home from '@/views/Home.vue'
import {CountriesMeta, OpenDataMeta, ContactMeta, CountryMeta , ErrorMeta} from '@/router/meta.js'
import countries from "@/assets/countries.csv";


const countryList = countries.map(d => d.url).join('|');

/*
 * DEFINE SECONDARY ROUTES
 * --------------------------------------------------------------------------------
 */

// ADMIN ROUTES 
// --------------------------------------------------------------------------------
const AdminRoutes = [
  { 
    path: 'indices', 
    name: 'IndicesAdmin', 
    component: () => import('@/admin-views/IndicesAdmin.vue')
  },
  { 
    path: 'contratos', 
    name: 'ContractsAdmin', 
    component: () => import('@/admin-views/ContractsAdmin.vue')
  },
  { 
    path: 'contratos/crear/:country?', 
    name: 'AddContractsAdmin', 
    component: () => import('@/admin-views/AddContractsAdmin.vue')
  },
  { 
    path: 'contratos/:id/:country?', 
    name: 'UpdateContractsAdmin', 
    component: () => import('@/admin-views/UpdateContractsAdmin.vue')
  },
  { 
    path: 'publicaciones', 
    name: 'PublicationsAdmin', 
    component: () => import('@/admin-views/PublicationsAdmin.vue')
  },
  { 
    path: 'publicaciones/crear', 
    name: 'AddPublicationAdmin', 
    component: () => import('@/admin-views/AddPublicationAdmin.vue')
  },
  { 
    path: 'publicaciones/:id/:country?', 
    name: 'UpdatePublicationAdmin', 
    component: () => import('@/admin-views/UpdatePublicationAdmin.vue')
  },
  { 
    path: 'usuario', 
    name: 'UserAdmin', 
    component: () => import('@/admin-views/UserAdmin.vue')
  },
  { 
    path: 'usuarios', 
    name: 'UsersAdmin', 
    component: () => import('@/admin-views/UsersAdmin.vue')
  },
  { 
    path: 'usuarios/crear', 
    name: 'AddUserAdmin', 
    component: () => import('@/admin-views/AddUserAdmin.vue')
  },
  { 
    path: 'usuarios/:id', 
    name: 'UpdateUserAdmin', 
    component: () => import('@/admin-views/UpdateUserAdmin.vue')
  }
];

// EFFICIENCY ROUTES 
// --------------------------------------------------------------------------------
const EfficiencyRoutes = [
  { 
    path: '', 
    name: 'EfficiencyObjective1', 
    component: () => import('@/views/EfficiencyObjective1.vue')
  },
  { 
    path: 'objetivo-2', 
    name: 'EfficiencyObjective2', 
    component: () => import('@/views/EfficiencyObjective2.vue')
  },
  { 
    path: 'objetivo-4', 
    name: 'EfficiencyObjective4', 
    component: () => import('@/views/EfficiencyObjective4.vue')
  },
  { 
    path: 'objetivo-9', 
    name: 'EfficiencyObjective9', 
    component: () => import('@/views/EfficiencyObjective9.vue')
  },
]

// RELEVANCE ROUTES 
// --------------------------------------------------------------------------------
const RelevanceRoutes = [
  { 
    path: '', 
    name: 'RelevanceObjective1', 
    component: () => import('@/views/RelevanceObjective1.vue')
  },
  { 
    path: 'objetivo-4', 
    name: 'RelevanceObjective4', 
    component: () => import('@/views/RelevanceObjective4.vue')
  },
  { 
    path: 'objetivo-9', 
    name: 'RelevanceObjective9', 
    component: () => import('@/views/RelevanceObjective9.vue')
  },
  { 
    path: 'objetivo-10', 
    name: 'RelevanceObjective10', 
    component: () => import('@/views/RelevanceObjective10.vue')
  },
]

// SERVICES ROUTES 
// --------------------------------------------------------------------------------
const ServicesRoutes = [
  { 
    path: '', 
    name: 'ServicesObjective3', 
    component: () => import('@/views/ServicesObjective3.vue')
  },
  { 
    path: 'objetivo-6', 
    name: 'ServicesObjective6', 
    component: () => import('@/views/ServicesObjective6.vue')
  },
  { 
    path: 'objetivo-7', 
    name: 'ServicesObjective7', 
    component: () => import('@/views/ServicesObjective7.vue')
  },
  { 
    path: 'objetivo-8', 
    name: 'ServicesObjective8', 
    component: () => import('@/views/ServicesObjective8.vue')
  },
]

// COUNTRY ROUTES 
// --------------------------------------------------------------------------------
const CountryRoutes = [
  { 
    path: '', 
    name: 'Situation', 
    component: () => import('@/views/Situation.vue')
  },
  { 
    path: 'semaforo', 
    name: 'CountryStatus', 
    component: () => import('@/views/CountryStatus.vue')
  },
  { 
    path: 'eficiencia', 
    name: 'Efficiency', 
    component: () => import('@/views/Efficiency.vue'),
    children : EfficiencyRoutes
  },
  { 
    path: 'pertinencia', 
    name: 'Relevance', 
    component: () => import('@/views/Relevance.vue'),
    children : RelevanceRoutes
  },
  { 
    path: 'servicios', 
    name: 'Services', 
    component: () => import('@/views/Services.vue'),
    children : ServicesRoutes
  },
  { 
    path: 'contratos', 
    name: 'Contracts', 
    component: () => import('@/views/Contracts.vue')
  },
  { 
    path: 'contrato/:id', 
    name: 'Contract', 
    component: () => import('@/views/Contract.vue')
  },
  { 
    path: 'publicaciones', 
    name: 'Publications', 
    component: () => import('@/views/Publications.vue')
  }
]

/*
 * DEFINE THE MAIN ROUTES
 * --------------------------------------------------------------------------------
 */
const routes = [
  // HOME
  { 
    path: '/', 
    name: 'Home', 
    component: Home 
  },
  // COUNTRIES
  { 
    path: '/paises', 
    name: 'Countries', 
    component: () => import('@/views/Countries.vue'), 
    meta: CountriesMeta
  },
  // OPEN DATA
  { 
    path: '/datos-abiertos', 
    name: 'OpenData', 
    component: () => import('@/views/OpenData.vue'), 
    meta: OpenDataMeta
  },
  // STATUS
  { 
    path: '/semaforo', 
    name: 'Status', 
    component: () => import('@/views/Status.vue')
  },
  // CONTACT
  { 
    path: '/contacto', 
    name: 'Contact', 
    component: () => import('@/views/Contact.vue'), 
    meta: ContactMeta
  },
  // ABOUT
  { 
    path: '/acerca-de-observadatos', 
    name: 'About', 
    component: () => import('@/views/About.vue')
  },
  // COUNTRY
  {
    path : `/pais/:country(${countryList})`,
    name : 'Country',
    component: () => import('@/views/Country.vue'),
    meta : CountryMeta,
    children : CountryRoutes
  },
  // Notes
  { 
    path: '/notas-metodologicas', 
    name: 'Notes', 
    component: () => import('@/views/MethodologicalNotes.vue')
  },
  { 
    path: '/login', 
    name: 'LoginAdmin', 
    component: () => import('@/admin-views/LoginAdmin.vue')
  },
  { 
    path: '/administrador', 
    name: 'Admin', 
    component: () => import('@/admin-views/Admin.vue'),
    children : AdminRoutes
  },
  // 400
  {
    path : '/:pathMatch(.*)*',
    name : '404',
    component: () => import('@/views/404.vue'),
    meta : ErrorMeta
  }
]

/*
 * CREATE THE ROUTER
 * --------------------------------------------------------------------------------
 */
const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior (to, from) {
    if(to.params.country && from.params.country){
      return;
    }
    else{
      return { top: 0 }
    }
  }
});

router.beforeEach((to) => {
  const routes = AdminRoutes.map(r => r.name );
  const actual = to.name;
  const token  = localStorage.getItem("access_token");
  if(routes.indexOf(actual) != -1 && ! token){
    return {
      name : "LoginAdmin"
    }
  }
})

export default router
