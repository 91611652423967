export const CountriesMeta = {
  title : "Países del Observatorio de datos Abiertos en VIH Alianza Liderazgo en Positivo y Poblaciones Claves",
  metaTags : [
    {
      name    : 'description',
      content : 'Países del Observatorio de datos Abiertos en VIH Alianza Liderazgo en Positivo y Poblaciones Claves'
    },
    {
      property: 'og:description',
      content : 'Países del Observatorio de datos Abiertos en VIH Alianza Liderazgo en Positivo y Poblaciones Claves'
    }
  ]
};

export const OpenDataMeta = {
  title : "Datos abiertos en :country",
  metaTags : [
    {
      name    : 'description',
      content : 'Datos abiertos de :country de gasto e inversión en atención al VIH y población clave'
    },
    {
      property: 'og:description',
      content : 'Datos abiertos de :country de gasto e inversión en atención al VIH y población clave'
    }
  ]
};

export const ContactMeta = {
  title : "Contacto",
  metaTags : [
    {
      name    : 'description',
      content : 'Contacta a observadatos'
    },
    {
      property: 'og:description',
      content : 'Contacta a observadatos'
    }
  ]
};

export const CountryMeta = {
  title : "Información del país",
  metaTags : [
    {
      name    : 'description',
      content : 'La información de un país'
    },
    {
      property: 'og:description',
      content : 'La información de un país'
    }
  ]
}

export const ErrorMeta = {
  title : "Página no encontrada",
  metaTags : [
    {
      name    : 'description',
      content : 'Página no encontrada en Observatorio de datos Abiertos en VIH Alianza Liderazgo en Positivo y Poblaciones Claves'
    },
    {
      property: 'og:description',
      content : 'Página no encontrada en Observatorio de datos Abiertos en VIH Alianza Liderazgo en Positivo y Poblaciones Claves'
    }
  ]
}

export const HandleMeta = (to, from, next) => {
  const store = require('../store');
  const country = to.params.country ? (store.default.getters.getCountry(to.params.country) || '') : "";

  //const country = to.params.country ? store.getters.getCountry( to.params.country ) : "";
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
  //const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if(nearestWithTitle) document.title = nearestWithTitle.meta.title.replaceAll(':country', country);

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if(!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      let str = tagDef[key].replaceAll(':country', country);
      tag.setAttribute(key, str);
    });

    // We use this to track which meta tags we create, so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  })
  // Add the meta tags to the document head.
  .forEach(tag => document.head.appendChild(tag));

  next();
};