/**
 * LOAD DEPENDENCIES
 * --------------------------------------------------------------------------------
 */
import { createStore } from 'vuex'
// import {csv, json} from "d3-fetch";
import {format} from "d3-format";
import countries from "@/assets/countries.csv";
import texts from "@/assets/texts.csv"
import maps from "@/assets/maps.json"
import indexGuide from "@/assets/indices-full.json";
// import axios from "axios";

import actions from "@/store/actions"

/**
 * CONSTANTS
 * --------------------------------------------------------------------------------
 */
//  const server         =  process.env.VUE_APP_SERVER;
// const indicesURL      = '/js/data/indices.csv';
// const publicationsURL = '/js/data/publicaciones.csv';
// const contractsURL    =  id => `/js/data/contracts/${id}_contracts.json`
// const textIndices     = ['país'];
const currencies      = ["USD","GTQ","HNL","COP","SVC","PYG","BOB","BOV","CRC","NIO","PAB","PEN"];
const sources         =  [
  {
    source_name : "Colombia Compra Eficiente (CCE)",
    url         : "https://colombiacompra.gov.co/transparencia/api",
    country     : "COL"
  },
  {
    source_name : "Datos Abiertos – Servicio Nacional de Contratación Pública de Ecuador",
    url         : "https://portal.compraspublicas.gob.ec/sercop/datos_abiertos/",
    country     : "ECU"
  },
  {
    source_name : "Datos Abiertos DNCP - Contrataciones Públicas de Paraguay",
    url         : "https://www.contrataciones.gov.py/datos/data",
    country     : "PRY"
  },
  {
    source_name : "Guatecompras",
    url         : "https://www.guatecompras.gt/",
    country     : "GTM"
  },
  {
    source_name : "Portal de Contrataciones Abiertas de Honduras",
    url         : "http://www.contratacionesabiertas.gob.hn/",
    country     : "HND"
  },
  {
    source_name : "Portal de Transparencia - El Salvador",
    url         : "https://www.transparencia.gob.sv/api/v1/home",
    country     : "SLV"
  }
];

const icons = [
  {
    name : "personas",
    words : ["persona", "personas", "población", "mujeres", "hombres", "niños", "adultos", "estigma", "género", "gente", "madre", "jóvenes", "huérfanos","embarazadas"]
  },
  {
    name : "gasto",
    words : ["inversión", "gasto", "costo"]
  },
  {
    name : "pruebas",
    words : ["pruebas", "prueba", "hepatitis"]
  },
  {
    name : "porcentaje",
    words : ["porcentaje", "porcentaje de pruebas", "%"]
  },
  {
    name : "prevalencia",
    words : ["prevalencia", "diagnóstico", "incidencia", "tasa","métricas"]
  },
  {
    name : "tratamiento",
    words : ["tratamiento","antirretroviral","art","tabs","personas en tratamiento"]
  }
];
const defaultIcon     = "gasto";
const classColorGuide = ["od_colorguide","od_colorguide _25", "od_colorguide _50", "od_colorguide _75", "od_colorguide _89",  "od_colorguide _90"];
const shortTextColumn = "resumen";
const situationColumn = "situacion";
const situationTitles = ["Incidencia y gasto en VIH", "Avance en el cumplimiento de la Estrategia 90-90-90", "Transmisión maternoinfantil",
                         "Datos abiertos sobre VIH", "Poblaciones clave"];

export default createStore({
/**
 * STATE
 * --------------------------------------------------------------------------------
 */
  state: {
    currencies,
    countries : countries.sort( (a,b) => a.name > b.name  ? 1 : -1),
    indexGuide,
    classColorGuide,
    maps,
    data : null,
    publications : null,
    contracts : {},
    sources,
    icons,
    defaultIcon,
    texts,
    token         : localStorage.getItem("access_token") || null,
    token_country : localStorage.getItem("access_token_country") || null,
    token_type    : localStorage.getItem("access_token_type") || null,
    users : []
  },

/**
 * MUTATIONS
 * --------------------------------------------------------------------------------
 */
  mutations: {
    saveIndicesToState(state, data){
      state.data = data;
    },

    savePublicationsToState(state, data){
      state.publications = data;
    },

    savePublicationToState(state, data){
      state.publications.push(data);
    },

    updatePublicationToState(state, data){
      const item = state.publications.find(d => d.id == data.id && data.country == d.country);
      
      item.title       = data.title;
      item.description = data.description;
      item.category    = data.category;
      item.author      = data.author;
      item.publication = data.publication
      item.date        = data.date;
      item.cover       = data.cover;
    },

    deletePublicationFromState(state, data){
      const item = state.publications.find(d => d.id == data.id && data.country == d.country);
      const index = state.publications.indexOf(item);
      state.publications.splice(index, 1);
    },

    saveContractsToState(state, data){
      state.contracts[data.country] = data.contracts;
    },

    saveContractToState(state, data){
      let country = state.token_type == 1 ? data.country :  state.token_country;
      state.contracts[country].push(data);
    },

    updateContractToState(state, data){
      let _country = state.token_type == 1 ? data.country :  state.token_country;
      let country  = _country.length == 3 ? _country : state.countries.find(d => d.name == _country).id;
      const item   = state.contracts[country].find(d => d.id == data.id);

      // item.id       = data.id;
      item.title    = data.title;
      item.provider = data.provider;
      item.state    = data.state;
      item.type     = data.type;
      item.amount   = data.amount;
      item.currency = data.currency;
      item.items    = data.items;
      item.diff     = data.diff;
      item.days     = data.days;
      item.file     = data.file;
      item.buyer    = data.buyer;
      item.date     = data.contract;
    },

    deleteContractFromState(state, data){
      const country = state.token_type == 1 ? data.country : state.token_country;
      const item = state.contracts[country].find(d => d.id == data.id);
      const index = state.contracts[country].indexOf(item);
      state.contracts[country].splice(index, 1);
    },


    saveLoginInfo(state, data){
      state.token         = data.token;
      state.token_country = data.country;
      state.token_type    = data.type;
      localStorage.setItem("access_token", data.token);
      localStorage.setItem("access_token_country", data.country);
      localStorage.setItem("access_token_type", data.type);
    },

    removeLoginInfo(state){
      state.token = null;
      state.token_country = null;
      localStorage.removeItem("access_token");
      localStorage.removeItem("access_token_country");
      localStorage.removeItem("access_token_type");
    },

    createUsers(state, users){
      state.users = users;
    },

    saveUserToState(state, data){
      state.users.push(data);
    },

    updateUserToState(state, data){
      const user = state.users.find(d => d.id == data.id);
      user.type    = data.type;
      user.country = data.country;
    },

    deleteUserFromState(state, data){
      const item = state.users.find(d => d.id == data.id);
      const index = state.users.indexOf(item);
      state.users.splice(index, 1);
    },
  },

/**
 * GETTERS
 * --------------------------------------------------------------------------------
 */
  getters : {
    getPublications : state => country => {
      if(!state.publications) return null;
      if(!country) return state.publications.filter(d => d.id)
      return state.publications.filter(d => d.country == country );
    },

    getSituation : state => country => {
      const item = state.texts.find(d => d.country == country && d.indicador == situationColumn );
      if(!item) return null;
      const staging = item.contenido.split("\n").map(d => {
        if(situationTitles.indexOf(d) != -1){
          return `<div class="ip_separator bottom mt-3"></div><h3 class="mb-4">${d}</h3>`;
        }
        else{
          return `<p>${d}</p>`;
        }
      }).join("")
      return staging;
    },

    getIndicatorText : state => (eje, objetivo, country) => {
      const item = state.texts.find(d => d.eje == eje && d.objetivo == objetivo && d.country == country);
      if(!item) return null;
      const staging = item.contenido.split("\n").map(d => {
        return `<p>${d}</p>`;
      }).join("")
      return staging;
    },

    getShortText : state => country => {
      const item = state.texts.find(d => d.country == country && d.indicador == shortTextColumn );
      if(!item) return null;
      return item.contenido;
    },

    getText : state => (country, index) => {
      const item = state.texts.find(d => d.country == country && d.indicador == index );
      if(!item) return null;
      return item.contenido;
    },

    /**
     * return the data for the axis graphs
     * @param {Array} indices 
     * @param {Array} items 
     * @returns 
     */
    getDataForObjectiveGraphs : () => (indices, items, objective) => {
      if(!indices || !items) return null;

      const filtered = indices.filter(d => d.objective == objective)
      if(!filtered.length) return [];

      const res = filtered.map(index => {
        const values = items.map(d => {
          return {
            year : +d['año'],
            value : d[index.column],
            type : index.column.indexOf("porcentaje") != -1 ? "porcentaje" : "number"
          }
        }).filter(d => d.value != null).sort( (a,b) => a.year > b.year ? -1  : 1);

        return {
          ...index,
          values
        }
      });

      return res;
    },

    /**
     * 
     * @param {Object} state 
     * @param {Object} getters 
     * @param {Number} axis 
     * @param {Array} items 
     * @returns 
     */
    getIndicesFromAxis : (state, getters) => (axis, items) => {
      if(!items.value) return null;
      const list = state.indexGuide.filter(d => d.axis == axis);
      return list.map(d => {
        return {
          name : d.name,
          axis : d.axis,
          objective : d.objective,
          value : getters.getLatestValueForIndex(d.db_name, items),
          column : d.db_name,
          format : d.format
        }
      })
    },


    /**
     * get the data for the star graph from all countries
     * @param {State} state 
     * @param {Number} year 
     * @returns Array
     */
    getVectors : state => year => {
      if(!state.data) return null;
      if(!year) return null;
      const items = state.data.filter(d => d['año'] == year);
      
      return items.map(d => {
        return {
          country : d['país'],
          gasto : d['promedio_gasto_per_capita'],
          incidencia : d['promedio_incidencia']
        }
      });
    },

    getVector : state => (year, country) => {
      if(!state.data || !year || !country) return null;
      const item = state.data.find(d => d['año'] == year && d['país'] == country);
      if(!item) return null;

      return [{
        country,
        gasto : item['promedio_gasto_per_capita'],
        incidencia : item['promedio_incidencia']
      }]
    },

    /**
     * get the color for the traffic light
     * @param {State} state 
     * @param {String} index 
     * @param {Number} value 
     * @returns String
     */
    getColor : state => (index, value) => {
      const guide = state.indexGuide.find(d => d.db_name == index);
      if(!guide || value == null) return state.classColorGuide[0];

      if(guide.direction.indexOf('up') != -1){
        if(value < 25) return state.classColorGuide[1];
        else if(value >= 25 && value <= 50) return state.classColorGuide[2];
        else if(value > 50 && value <= 75) return state.classColorGuide[3];
        else if(value > 75 && value <= 89){
          return state.classColorGuide[4];
        }
        else{
          return state.classColorGuide[5];
        }
        
      }
      else{
        if(value >=90) return state.classColorGuide[1];
        else if(value >= 76 && value < 90) return state.classColorGuide[2];
        else if(value >= 51 && value < 76) return state.classColorGuide[3];
        else if(value >= 25 && value < 51) return state.classColorGuide[4];
        else{
          return state.classColorGuide[5];
        } 
      }
    },

    /**
     * 
     * @param {State} state 
     * @returns 
     */
    getCountryIndices : state => country => {
      if(!state.data) return null;

      return state.data.filter(d => d['país'] == country.id);
    },

    /**
     * search the latest value from a given index inside a data collection
     * @param {String} indice
     * @param {Array} items
     * @returns Number
     */
    getLatestValueForIndex : () => (indice, items) => {
      if(!items.value) return null;
      const results = items.value.filter(d => d[indice]);

      if(!results.length) return null;
      if(results.length == 1) return results[0][indice];

      return results.sort( (a,b) => a['año'] > b['año'] ? -1 : 1 )[0][indice];
    },

    getValueFromIndexWithCountryAndYear : state => (index, country, year) => {
      if(!state.data) return null;

      let value = state.data.find(d => d['año'] == year && d['país'] == country);
      return value ? value[index] : null;
    },

    /**
     * return an array of years that indicates the avaliable data for a given index and country
     * @param {State} state
     * @param {String} index
     * @param {String} country 
     * @returns Array 
     */
    getAvaliableYearsFromIndex : state => (index, country) => {
      if(!state.data) return null;
      
      let items;
      if(country){
        items = state.data.filter(d => d[index] != null && d['país'] == country)
      }
      else{
        items = state.data.filter(d => d[index] != null)
      }

      let years = [...new Set(items.map(d => d['año']))].sort().reverse();

      return years;
    },

    /**
     * get one index from every contry from sa given year
     * @param {State} state 
     * @param {String} indice 
     * @param {Number} year 
     * @returns Array
     */
    getCountriesIndiceByYear : state => (indice, year) => {
      if(!state.data || !year) return null;

      // console.log("misterio:", state.data.filter( d => d['año'] == year));
      return state.data.filter( d => d['año'] == year).map(item => {
        return {
          country : item['país'],
          value : item[indice],
          url : state.countries.find(d => d.id == item['país']).url,
          name : state.countries.find(d => d.id == item['país']).name
        }
      })
    },

    /**
     * format numbers
     * @param {Number} amount 
     * @returns String
     */
    formatNumber : () => amount => {
      return Number.isInteger(amount) ? f2(amount) : f(amount);
    },

    /**
     * get the appropiate class for a given icon
     * @param {State} state 
     * @param {String} title 
     * @returns 
     */
    getIcon : state => title => {
      let icon;
      if(!title) return state.defaultIcon;
      title = title.toLowerCase();
      icon  = state.icons.find( d =>{
        let matches = d.words.map( word => title.search(word) != -1 ).filter(e => e);

        return matches.length;
      })

     return icon ? icon.name : state.defaultIcon;
     
    },

    /**
     * ADMIN GETTERS 
     * --------------------------------------------------------------------------------
     */
    loggedIn : state => {
      return state.token;
    },

    updateCSVValidKeys : state => items => {
      // año	indicador	valor
      if(!items || !items.length) return null;
      const keys         = Object.keys(items[0]);
      const validKeys    = state.indexGuide.map(d => d.db_name);

      if(keys.indexOf('año') == -1) return null;
      if(keys.indexOf('indicador') == -1) return null;
      if(keys.indexOf('valor') == -1) return null;

      const currentKeys  = [...new Set(items.map(d => d.indicador))];
      const intersection = currentKeys.filter( d => validKeys.includes(d) ) 

      return intersection;
    },

    getCSVValuesForStaging : (state, getters) => (items, country) => {
      return items.map(item => {
        const indices = Object.keys(item).filter(d => d != 'año' && d != 'país');
        return indices.map(indice => {
          let res     = {};
          let ind     = state.indexGuide.find(d => d.db_name == indice); 
          let exist   = getters.getValueFromIndexWithCountryAndYear(indice, country.id, item['año']);
          let check   = getters.getStatusAndValueForNewCSVValue(ind, exist);
          res.year    = item['año'];
          res.value   = item[indice];
          res.db_name = indice;
          res.name    = ind ? ind.name : indice;
          res.exist   = exist;
          res.message = check.message;
          res.status  = check.status;

          return res;
        });
      }).flat();
    },

    getStatusAndValueForNewCSVValue : () => (index, exist) => {
      if(!index){
        return {
          status : "error",
          message : "el indicador no existe en el sistema"
        }
      }
      else if(exist){
        return {
          status : "warning",
          message : `el valor ya existe en el sistema (${exist}) y será reemplazado por el nuevo valor`
        }
      }
      else{
        return {
          status : "",
          message : ""
        }
      }
    },

    fixDate : () => date => {
      if(date.indexOf("/") != -1){
        const items = date.split("/");
        return `${items[2]}-${items[1]}-${items[0]}`;
      }
      else if(date.indexOf("T") != -1){
        return date.split("T")[0]
      }
      else{
        return date;
      }
    }
  },
  actions,

// /**
//  * ACTIONS
//  * --------------------------------------------------------------------------------
//  */
//   actions: {
//     /**
//      * Load indices from CSV
//      * @param {*} param0 
//      */
//     getIndicesFromCSV({commit}){
//       csv(indicesURL).then(res => {
//         parseIndicesFromStringToNumber(res);
//         commit('saveIndicesToState', res);
//       })
//     },

//     /**
//      * Load publications from CSV
//      * @param {*} param0 
//      */
//      getPublicationsFromCSV({commit}){
//       csv(publicationsURL).then(res => {
//         commit('savePublicationsToState', res);
//       })
//     },

//     /**
//      * Load contracts from multiple CSV
//      * @param {*} param0 
//      */
//     loadContractsFromCSV({commit}){
//       let countrieswc = countries.filter(d => d.contracts);
//       for(let country of countrieswc){
//         let url = contractsURL(country.id);
//         json(url).then(d => {
//           commit('saveContractsToState', {
//             country : country.id,
//             contracts : d
//           })
//         }).catch( () => {
//           commit('saveContractsToState', {
//             country : country.id,
//             contracts : null
//           })
//         })
//       }
//     },
//     /**
//      * ADMIN ACTIONS 
//      * --------------------------------------------------------------------------------
//      */
//     login({commit}, credentials){
//       return new Promise((resolve, reject) => {
//         axios.post(`${server}/login`,{
//           email    : credentials.email,
//           password : credentials.password
//         })
//         .then(res => {
//           commit('saveLoginInfo', res.data);
//           return resolve(res.data);
//         })
//         .catch(e => {
//           return reject(e);
//         });
//       })
//     },

//     validateSession(/*{state}*/){
//       return new Promise( (resolve/*, reject*/) => {
//         return resolve(true);
//       });
//     }
//   },

/**
 * MODULES
 */
  modules: {
  }
})

/**
 * HELPERS
 * --------------------------------------------------------------------------------
 */

/**
 * Convert every index from string to number, if the data comes from CSV
 * @param {Array} indices 
 * @returns Array
 */
// const parseIndicesFromStringToNumber = indices => {
//   const keys = Object.keys(indices[0]);
//   for(let indice of textIndices.concat(keys.filter(d => d.indexOf('_moneda') != -1))){
//     let pos = keys.indexOf(indice);
//     keys.splice(pos, 1);
//   }
//   for(let indice of indices){
//     for(let key of keys){
//       indice[key] = indice[key] ?  +indice[key] : null;
//     }
//   }
//   return indices;
// }

// format integers and decimals
const f  = format(",.2f");
const f2 = format(",");