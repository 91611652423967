<script>
/**
 * DEPENDENCIES
 */
import {ref, computed} from "vue";
import {useStore} from "vuex";
import * as turf from "@turf/turf";
import chroma from "chroma-js";
import mapsFull from "@/assets/maps.json"
import { useRouter } from 'vue-router';
const d3 = Object.assign(
  {},
  require("d3-selection"),
  require("d3-geo"),
  require("d3-fetch")
);

/**
 * CONSTANTS
 */
const scale = 430;
const multiply = 10;
const maxBars = 5;
const colors = {
  default: "#f0f2ef",
  value: "grey",
  null: "#999",
  list: ["#d7191c", "#fdae61", "#ffffbf", "#abd9e9", "#2c7bb6"],
//  list: ["#c6dbef", "#9ecae1", "#6baed6", "#3182bd", "#08519c"],
};
const blackList = ["Falkland Islands", "Canada", "Mexico", "United States of America",
                   "Cuba", "Greenland", "The Bahamas"];
const maps = Object.assign({}, mapsFull)
maps.features = mapsFull.features.filter(d => blackList.indexOf(d.properties.admin) == -1);

const show = ref(false);
const clientX = ref(0);
const clientY = ref(0);
const tooltipText = ref("");

/**
 * METHODS
 */
const getCountryFillColor = guide => (items, country) => {
  const item =  items.find(d => d.country == country.properties.iso_a3);
  if(!item) return colors.default;
  if(!item.value) return colors.null;
  
  for(let i = 0; i <= guide.value.limits.length; i++){
    if(item.value <= guide.value.limits[i+1])return guide.value.bands[i];
  }

  return colors.null;
}

const getDimensions = () => {
  let bbox = turf.bbox(maps),
      w    = bbox[2] - bbox[0],
      h    = bbox[3] - bbox[1],
      res = [Math.abs(w) * multiply, Math.abs(h) * multiply];
  
  return res;
}

const getPathGenerator = dimensions => {
  const center = turf.center(maps).geometry.coordinates;
  const proj   = d3.geoMercator().translate([dimensions[0]/2, dimensions[1]/2])
    .scale(scale)
    .center(center);
  
  return d3.geoPath().projection(proj)
}


/**
 * VUE COMPONENT
 */
export default {
  props: ["values", "indice"],
  setup(props) {
    const store = useStore();
    const router = useRouter();
    // const maps = store.state.maps;
    const dimensions = getDimensions();
    const path       = getPathGenerator(dimensions);

    const goToCountry = feature => {
      const country = props.values.find(d => d.country == feature.properties.iso_a3)
      router.push({ name: 'Situation', params: { country: country.url } })
    }

    const findCountry = p => props.values.find(d => d.country == p.properties.iso_a3);
    const onHover = (e, p) => {
      const country = findCountry(p);
      console.log(country);
      
      if(country){
        show.value = true;
        clientX.value = e.clientX + 5;
        clientY.value = e.clientY -20;
        tooltipText.value = `${country.name}: ${country.value ? country.value + '%' : 'no hay información'}`;
      }
    }
    
    const onMove = e => {
      show.value = true;
      clientX.value = e.clientX + 5;
      clientY.value = e.clientY -20;
    }

    /**
     * COMPUTED
     * */
    const guide = computed( () => {
      const items  = props.values.map(d => d.value).filter(d => d);
      const num    = Math.min(items.length, maxBars);
      const limits =  chroma.limits(items, 'q', num);
      const bands  = chroma.scale(colors.list).colors(num);
      return {
        limits, 
        bands
      }
    })

    return {
      scale,
      multiply,
      maxBars,
      colors,
      maps,
      dimensions,
      path,
      getCountryFillColor : getCountryFillColor(guide),
      guide,
      format : store.getters.formatNumber,
      goToCountry,

      onHover,
      onMove,
      show,
      clientX,
      clientY,
      tooltipText,
      findCountry
    };
  },
};
</script>



<template>
  <div>
    <div id="main-map">
      <svg
        preserveAspectRatio="xMinYMin meet"
        width="100%"
        height="100%"
        :viewBox="`0 0 ${dimensions[0]} ${dimensions[1]}`"

        style="background: #d5dade"
        class="svg-content">

        <g v-for="(p, i) in maps.features" :key="`f-${i}`">
          <path v-if="findCountry(p)"

            class="continent"
            :d="path(p)"
            :fill="getCountryFillColor(values, p)"
            stroke="#dcebef"
            stroke-width="1"
            v-on:click="goToCountry(p)"
            v-on:mousemove="onMove" 
            v-on:mouseover="e => onHover(e, p)" 
            v-on:mouseout="show = false" />
          
          <path v-else
            class="continent"
            :d="path(p)"
            :fill="getCountryFillColor(values, p)"
            stroke="#dcebef"
            stroke-width="1" />
        </g>
      </svg>
      <ul v-if="guide.bands.length > 1" class="od_color">
        <li v-for="(band, i) in guide.bands" :key="`b-${i}`">
          <span
            class="od_color_guide"
            :style="{
              background: band,
              //   display    : 'inline-block',
              width: '100%',
              height: '1em',
            }"
          ></span>
          <span class="od_color_text">{{
            `${format(guide.limits[i])} - ${format(guide.limits[i + 1])}`
          }}</span>
        </li>
      </ul>
    </div>
  </div>
  <div id="tooltip" :style="{ top: `${clientY}px`, left: `${clientX}px` }" v-if="show">{{tooltipText}} </div>
</template>

<style scoped>
path.continent {
  stroke-width: 0.5;
}

#tooltip {
  background: white;
  color: #444;
  font-family: 'Source Sans Pro',Helvetica, sans-serif;  
  padding: 5px 10px;
  position: fixed;
  background-color: white;
  box-shadow: 1px 1px 5px rgba(0,0,0,0.2);
}
</style>