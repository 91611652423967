<script>
/**
 * LOAD DEPENDENCIES
 * --------------------------------------------------------------------------------
 */
import { computed, ref } from "vue";
import { useStore } from "vuex";
// import {mean} from "d3-array";
import MainMap from "@/components/MainMapContainer";
import CountryCard from "@/components/CountryCard";
// import CountryBars from "@/components/CountryBars";
import StarGraph from "@/components/StarGraph";
// import HeatMap from '@/components/HeatMap';
import ColorGuide from "@/components/ColorGuideTrafficLight";

/**
 * CONSTANTS
 * --------------------------------------------------------------------------------
 */
const retroviralesIndex ="porcentaje_de_personas_que_viven_con_vih_que_conocen_su_estado_serológico_y_reciben_tarv";


/**
 * REFS
 * --------------------------------------------------------------------------------
 */
const _year = ref(null);

/**
 * METHODS
 * --------------------------------------------------------------------------------
 */
const setYear = year => _year.value = year;

/**
 * VUE COMPONENT
 * --------------------------------------------------------------------------------
 */
export default {
  name: "Home",
  setup() {
    const store     = useStore();
    const countries = store.state.countries;
    const indices   = store.state.indexGuide.filter(d => [
      'Porcentaje de personas con VIH que reciben tratamiento que han suprimido su carga viral', 
      'Porcentaje de personas con VIH que reciben TARV',
      'Porcentaje de personas con VIH que conocen su estado serológico'].indexOf(d.name) != -1);
    const population = "personas_que_viven_con_vih_que_reciben_tarv";
    /**
     * COMPUTED
     * --------------------------------------------------------------------------------
     **/
    const years = computed(() => {
      return store.getters.getAvaliableYearsFromIndex(retroviralesIndex);
    });

    const year = computed(() => {
      if (!years.value) return null;
      if (!_year.value) return years.value[0];
      return _year.value;
    });

    const values = computed(() => {
      return store.getters.getCountriesIndiceByYear(
        retroviralesIndex,
        year.value
      );
    });

    const vectors = computed( () => {
      const res = store.getters.getVectors(year.value);
      if(!res) return null;
      return res.filter(d => d['gasto'] && d['incidencia']);
    });

    /**
     * TEMPLATE ELEMENTS
     * --------------------------------------------------------------------------------
     **/
    return {
      setYear,
      _year,
      year,
      years,
      values,
      countries,
      vectors,
      getIcon : store.getters.getIcon,
      getData : store.getters.getValueFromIndexWithCountryAndYear,
      format : store.getters.formatNumber,
      color  : store.getters.getColor,
      indices,
      population
    };
  },

  /**
   * COMPONENTS
   * --------------------------------------------------------------------------------
   **/
  components: {
    MainMap,
    CountryCard,
    // CountryBars,
    StarGraph,
    ColorGuide
    // CountryCard,
    // HeatMap
  },
};
</script>
<template>
  <div>
    <main-map />
    <section>
      <div class="container">
        <div class="row">
          <div class="col-sm-12">
            <h1>Eficiencia y pertinencia del gasto en VIH</h1>
            <div class="ip_separator bottom"></div>
          </div>
          <div class="col-sm-10 offset-sm-1">
            <p class="mt-4">
              La inversión hecha por los países para combatir al Virus de la
              Inmunodeficiencia Humana (VIH) y prevenir el Síndrome de la
              Inmunodeficiencia Adquirida (SIDA) influye de manera directa en la
              calidad de vida de las personas con VIH. <strong>79.90%</strong> de las personas con VIH en Bolivia, Colombia, Costa Rica, Ecuador, El Salvador, Guatemala, Honduras, Nicaragua, Panamá, Paraguay y Perú <strong>reciben tratamiento con antirretrovirales</strong>. </p>
            <p>Recibir tratamiento antirretroviral es uno de los <strong>derechos de las personas con VIH</strong> que debe ser garantizado por los Estados. El primer paso para lograrlo es que los gobiernos inviertan de una manera eficiente y pertinente, ya que las compras de estos insumos influyen de manera directa en la calidad de vida de las personas con VIH.</p>
            <p>Es por eso que el <strong>monitoreo de la eficiencia y pertinencia del gasto en VIH</strong> adquiere un papel central para entender no solo en qué están gastando los gobiernos sino cómo lo están haciendo y, en caso de ser necesario, redirigir estos esfuerzos para garantizar una prevención y atención exitosa de la epidemia de VIH. </p>
           </div>
           </div>

           <div class="row mb-4 pb-4">
            <div class="col-sm-10 offset-sm-1">
              <h3 class="mt-3">Personas con VIH que conocen su estado su estado serológico y reciben TARV en {{ year }} </h3>
            
        <div class="row mt-4 no-mobile">
          <div class="col-3">
            <h6>País</h6>
          </div>
          <div class="col-3">
            <h6 class="text-right">Personas</h6>
          </div>
          <div class="col-6">
            <div class="row">
              <div class="col-6">
                <h6>Porcentaje</h6>
              </div>
              <div class="col-6">
                <h6 class="text-right">
                  Meta 90% <span class="od_meta90"></span>
                </h6>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="ip_separator bottom"></div>
          </div>
        </div>
        <div class="row mt-4" v-for="(item, i) in values" :key="`ctry-${i}`">
          <div class="col-7 col-sm-3">
            <h5>
              <router-link :to="{ name: 'Situation', params: { country: item.url } }">{{ item.name }}</router-link>
            </h5>
          </div>
          <div class="col-5 col-sm-3" v-if="item.value">
            <p class="text-right">
              <strong v-if="getData(population, item.country, year)" class="od-data-attr" data-pp="personas">{{format(getData(population, item.country, year))}}</strong>
              <strong v-else class="od-data-attr od_danger" data-pp="personas">No hay datos disponibles</strong>
            </p>
          </div>
          <div class="col-5 col-sm-3" v-else>
            <p class="text-right">
              <strong class="od-data-attr od_danger" data-pp="personas">No hay datos disponibles</strong>
            </p>
          </div>

          <div class="col-sm-6 mtm-2">
            <div class="row" v-if="item.value">
              <div class="col-2 col-sm-2">
                <p class="text-right">{{ item.value }}%</p>
              </div>
              <div class="col-10 col-sm-10">
                <div class="od_bar percent">
                  <div class="od_value_90"></div>
                  <div
                    class="od_value_bar"
                    :style="{ width: `${item.value}%` }"
                    :data-value="`${item.value}%`"
                  ></div>
                </div>
              </div>
            </div>
            <div class="row" v-else>
              <div class="col-2 col-sm-2">
                <p class="text-right od_danger">ND</p>
              </div>
              <div class="col-sm-10">
                <div class="od_bar percent">
                  <div class="od_triangle_danger"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="ip_separator bottom"></div>
          </div>
        </div>

        <div class="row mt-4">
          <div class="col-12">
            <p class="text-right od_note">Fuente: <strong>Onusida</strong></p>
          </div>
        </div>

        </div>
          </div>

          <!--- Gasto per cápita e incidencia-->
          <div class="row">
            <div class="col-sm-12 mt-4">
              <h3>Gasto per cápita e incidencia de VIH 2016 - 2020</h3>
              <div class="ip_separator bottom"></div>
            </div>
            <div class="col-sm-10 offset-sm-1">
           <!-- <p>En los últimos cinco años, Guatemala, Nicaragua, Paraguay y Perú muestran un aumento en el gasto per cápita en VIH aumentó y una reducción en la incidencia de VIH. En Ecuador y El Salvador, sin embargo, el gasto per cápita disminuyó y la incidencia de VIH también se redujo. En Costa Rica tanto el gasto per cápita como la tasa de incidencia se han mantenido sin cambios durante este periodo. Los datos sobre gasto per cápita en VIH no están disponibles en Bolivia, Colombia, Honduras y Panamá, por lo que no es posible conocer la situación nacional.  </p>
            -->
              <p  class="mt-3">La relación entre la tasa de cambio anualizada de incidencia de VIH por cada 100 mil personas y la tasa de cambio anualizada de gasto per cápita en VIH solo es <strong>observable en seis de los 11 países</strong>: <router-link to="/pais/ecuador" class="od_link">Ecuador</router-link>, <router-link to="/pais/el-salvador" class="od_link">El Salvador</router-link>, <router-link to="/pais/costa-rica" class="od_link">Costa Rica</router-link>, <router-link to="/pais/panama" class="od_link">Panamá</router-link>, <router-link to="/pais/paraguay" class="od_link">Paraguay</router-link> y <router-link to="/pais/peru" class="od_link">Perú</router-link>. </p>
              <p>En los últimos cinco años, Costa Rica y Paraguay muestran un aumento en el gasto per cápita en VIH y una reducción en la incidencia de VIH. En El Salvador, Ecuador y Panamá, sin embargo, el gasto per cápita disminuyó y la incidencia de VIH también se redujo. En Perú el gasto per cápita disminuyó y la tasa de incidencia aumentó. </p>
              <p>En <router-link to="/pais/bolivia" class="od_link">Bolivia</router-link>, <router-link to="/pais/colombia" class="od_link">Colombia</router-link>, <router-link to="/pais/nicaragua" class="od_link">Nicaragua</router-link> y <router-link to="/pais/honduras" class="od_link">Honduras</router-link> no hay datos sobre el gasto en VIH. Esta falta de información hace imposible conocer y analizar cuál es la tasa de cambio anualizada de gasto per cápita en VIH. Es necesario impulsar la apertura de esta información en esos países para poder realizar el análisis para los 11 países parte de este proyecto. 
</p>
            </div>
            <div class="col-sm-12">
            <div class="row mt-4 pt-4">
              
              <div class="col-sm-3 offset-sm-2">
                <p class="sg_legend">Tasa de cambio anualizada de incidencia de VIH por cada 100,000 personas (%)</p>
              </div>
              <div class="col-sm-5">
                <ul class="sg_legend_list color_countries">
                  <!--<li><span class="bol"></span>Bolivia</li>-->
                  <!--<li><span class="colm"></span>Colombia</li>-->
                  <li><span class="cri"></span>Costa Rica</li>
                  <li><span class="ecu"></span>Ecuador</li>
                  <li><span class="slv"></span>El Salvador</li>
                   <!--<li><span class="gtm"></span>Guatemala</li>
                 <li><span class="hnd"></span>Honduras</li>
                  <li><span class="nic"></span>Nicaragua</li>-->
                  <li><span class="pan"></span>Panamá</li>
                  <li><span class="pry"></span>Paraguay</li>
                  <li><span class="per"></span>Perú</li>
                </ul>
              </div>
              <div class="col-sm-8 offset-sm-2">
              <star-graph v-if="vectors" :vectors="vectors" />
              </div>
              <div class="col-sm-8 offset-sm-2">
              <p class="text-center sg_legend">Tasa de cambio anualizada de gasto per capita en  VIH  (%)</p>
              </div>
            </div>

            </div>
            <div class="col-sm-12 mt-4">
              <h3 class="mt-4 pt-4">Estadísticas sobre poblaciones clave</h3>
              <div class="ip_separator bottom"></div>
            </div>
            <div class="col-sm-10 offset-sm-1 mt-3">
              <p>En la región existe una gran carencia de estadísticas sobre VIH desagregadas por poblaciones clave. Las personas usuarias 
                de drogas son las más relegadas en esta materia, ya que solo Colombia publica información sobre la prevalencia de VIH y 
                acceso a pruebas de detección de VIH para esta población. </p>
              <p>No obstante, las estadísticas para personas trans, personas trabajadoras sexuales, hombres que tienen sexo con hombres y 
                personas privadas de su libertad también son difíciles de encontrar en todos los países. En este punto destaca la falta 
                de información histórica al respecto, ya que los países que publican estos datos lo hacen solo para el año 2019, 
                lo que dificulta el análisis de la evolución de estos datos. </p>
            </div>
            
            <div class="col-sm-12 mt-4">
              <h3 class="mt-4">Discriminación</h3>
              <div class="ip_separator bottom"></div>
            </div>
            <div class="col-sm-10 offset-sm-1 mt-3 mb-4">
              <p>Entre los 11 países, solo Paraguay tiene disponible la información sobre la falta de atención médica a poblaciones clave debido al estigma y la discriminación. Al ser un tema que impacta de manera directa en el pleno goce de derechos de las personas con VIH, es indispensable impulsar que esta información esté disponible en el resto de los países. </p>
          </div>
        </div>

        <div class="row im_country mt-4 pt-4 pb-4">
          <div class="col-sm-6">
              <h5>Eficiencia del gasto</h5>
              <p>Si el gasto es eficiente, los recursos públicos están siendo utilizados en la producción de resultados. Es por esto que medir
              la eficiencia del gasto en VIH permite conocer si el dinero invertido por los gobiernos produce resultados o si es necesario redirigir estos gastos.</p>
          </div>
          <div class="col-sm-6">
              <h5>Pertinencia del gasto</h5>
              <p>Por otro lado, la pertinencia del gasto señala que la inversión está siendo realizada en respuesta a las necesidades, políticas y prioridades tanto nacionales como internacionales de las personas
               con VIH, así como a los compromisos internacionales suscritos por los países.</p>
          </div>
        </div>

        <div class="row mt-4 pt-4">
          <div class="col-12">
            <h2>Conoce la situación en cada país en 2020</h2>
          </div>
        </div>
        <!--- color guide-->
        <div class="row mt-4 pt-4 mb-4 pb-4">
          <div class="col-sm-8">
            <div class="row">
              <div class="col-sm-2">
                <div class="od_colorguide"></div>
                <p class="im_label sx">No disponible</p>
              </div>
              <div class="col-sm-2">
                <div class="od_colorguide _25"></div>
                <p class="im_label sx">&#x3c; 25%</p>
              </div>
              <div class="col-sm-2">
                <div class="od_colorguide _50"></div>
                <p class="im_label sx">25 - 50%</p>
              </div>
              <div class="col-sm-2">
                <div class="od_colorguide _75"></div>
                <p class="im_label sx">51 - 75%</p>
              </div>
              <div class="col-sm-2">
                <div class="od_colorguide _89"></div>
                <p class="im_label sx">76 - 89%</p>
              </div>
              <div class="col-sm-2">
                <div class="od_colorguide _90"></div>
                <p class="im_label sx">90% o mayor</p>
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <p class="im_label text-right">
              <strong>Primer 90:</strong> Conocen su estado<br />
              <strong>Segundo 90:</strong> Reciben TARV<br />
              <strong>Tercer 90:</strong> Indetectable
            </p>
          </div>
        </div>

        <!-- countries list -->
        <country-card v-for="item of countries" :country="item" :year="year" :key="`item-${item.id}`" />

        <!-- eje 1 semáforo-->
        <h2 class="mt-4 pt-4">Estatus de la región.</h2>
        <h3 class="mt-3">Avance de los compromisos 90-90-90</h3>
        <p>A continuación se muestra el progreso del país hacia los objetivos de la estrategia 90-90-90. El color depende del porcentaje alcanzado en cada uno de los objetivos (conocimiento del estado serológico, recepción de TARV y supresión de carga viral) y va de gris -en donde no hay información disponible- a azul -en donde el porcentaje es igual o mayor a 90. </p>
        <div class="row">
         <div class="col-sm-5">
          <h4>Metas Ascendentes &#x2191; </h4>
                <ColorGuide/>
          </div>
        </div> 
          <div class="table">
            <table class="table">
              <thead>
                <tr>
                  <th>Indicador</th>
                  <th class="od_p_5" v-for="country of countries" :key="`head-${country.id}`">
                    <span class="od_t_70">{{country.name}}</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="index of indices" :key="`table-${index.db_name}`">
                  <td>{{index.name}}</td>
                  <td class="od_p_5" v-for="country of countries" :key="`table-${index.db_name}-${country.id}`">
                    <div :class="`${color(index.db_name, getData(index.db_name, country.id, year))} _bg`">
                      {{getData(index.db_name, country.id, year) != null ? format(getData(index.db_name, country.id, year))+'%' : ''}}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        <div class="row">
          <div class="col-sm-8 offset-sm-2">
            <p><a href="/semaforo" class="od_dl_btn block"> Ver estatus de la región en los demás ejes→ </a></p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
