<template>
    <div class="row">
          <div class="col-2 pr-0">
            <div class="od_colorguide">
            </div>
            <p class="im_label sx">No disponible</p>
          </div>
          <div class="col-2 pl-0 pr-0">
            <div class="od_colorguide _25">
            </div>
            <p class="im_label sx"> &#x3c; 25%</p>
          </div>
          <div class="col-2 pl-0 pr-0">
            <div class="od_colorguide _50">
            </div>
            <p class="im_label sx">25 - 50%</p>
          </div>
          <div class="col-2 pl-0 pr-0">
            <div class="od_colorguide _75">
            </div>
            <p class="im_label sx">51 - 75%</p>
          </div>
          <div class="col-2 pl-0 pr-0">
            <div class="od_colorguide _89">
            </div>
            <p class="im_label sx">76 - 89%</p>
          </div>
          <div class="col-2 pl-0">
            <div class="od_colorguide _90">
            </div>
            <p class="im_label sx">90% o mayor</p>
          </div>
        </div>
</template>