<script>
/**
 * LOAD DEPENDENCIES
 * --------------------------------------------------------------------------------
 */
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { onMounted, computed } from 'vue';

/**
 * VUE COMPONENT
 * --------------------------------------------------------------------------------
 */
export default {
  setup(){
    const route        = useRoute();
    const store        = useStore();
    const token        = computed( () => store.state.token);
    const countrieswc  = store.state.countries.filter(d => d.contracts);
    const countrieswoc = store.state.countries.filter(d => !d.contracts);
    onMounted( () => {
      store.dispatch('getIndicesFromCSV');
      store.dispatch('loadContractsFromCSV');
      store.dispatch('getPublicationsFromCSV');
    } )
    return {
      route,
      countrieswc,
      countrieswoc,
      token
    }
  }
}
</script>



<template>
  <div id="app" :class="route.name">
    <header class="im_header home">
      <div class="container">
        <div class="row pb-4 mb-2">
            <div class="col-sm-8 col-lg-5">
              <h1 class="od_name"><router-link to="/"><span>Observatorio de datos Abiertos en VIH</span> Alianza Liderazgo en Positivo y Poblaciones Claves</router-link> </h1>
            </div>
            <div class="col-sm-4 col-lg-7">
              <p v-if="token" class="text-right"><router-link to="/administrador/indices" class="od_link_btn_hd">Administrador</router-link></p>
              <p v-else class="text-right"><router-link to="/login" class="od_link_btn_hd">Acceder</router-link></p>
            </div>
        </div>
        <div class="row" v-if="route.name == 'Home'">
            <div class="col-sm-9 col-lg-8 offset-sm-3 offset-lg-4">
              <h2>Monitoreamos el gasto e inversión en atención al <strong>VIH</strong> y <strong>población clave</strong></h2>
            </div>
        </div>
      </div>
      <!--nav-->
      <div id="nav">
        <div class="container">
          <div class="row">
            <div class="col">
              <router-link to="/paises">Países</router-link>
            </div>
            <div class="col">
              <router-link to="/semaforo">Estatus de la región</router-link>
            </div>
            <div class="col">
              <router-link to="/datos-abiertos">Datos Abiertos</router-link>
            </div>
            <div class="col">
              <router-link to="/acerca-de-observadatos">Acerca de Observadatos</router-link>
            </div>
            <!--
            <div class="col">
              <router-link to="/contacto">Contacto</router-link>
            </div>-->
          </div>
        </div>
      </div>
    </header>
    <router-view/>
    <footer>
      
      <div class="container mb-4 pb-4 pt-4 mt-4">
        <div class="row mb-4 pb-4">
          <div class="col-sm-3">
            <p><router-link to="/" class="od_name"><span>Observatorio de datos Abiertos en VIH</span> Alianza Liderazgo en Positivo y Poblaciones Claves</router-link></p>
          </div>
          <div class="col-sm-6 offset-sm-1">
            <div class="row">
              <div class="col-4 col-sm-3">
                <!-- COUNTRIES WITH CONTRACTS -->
                <ul>
                  <li v-for="country of countrieswc" :key="`${country.id}-link`">
                    <router-link :to="{ name: 'Country', params: { country: country.url }}">{{country.name}}</router-link>
                  </li>
                </ul>
              </div>
              <div class="col-4  col-sm-3">
                <!-- COUNTRIES WITHOUT CONTRACTS -->
                <ul>
                  <li v-for="country of countrieswoc" :key="`${country.id}-link`">
                    <router-link :to="{ name: 'Country', params: { country: country.url }}">{{country.name}}</router-link>
                  </li>
                </ul>
              </div>
              <div class="col-4  col-sm-4">
                <ul>
                  <li><router-link to="/semaforo">Estatus de la región</router-link></li>
                  <li><router-link to="/datos-abiertos">Datos abiertos</router-link></li>
                  <li><router-link to="/acerca-observadatos">Acerca de Observadatos</router-link></li>
                  <li><router-link to="/contacto">Contacto</router-link></li>

                </ul>
              </div>
            </div>
          </div>
          <div class="col-sm-2">
            <p><router-link to="/login" class="od_link_btn">Acceder</router-link></p>
          </div>
        </div>
      </div>
      <div class="od_update">
        <div class="container">
          <p>Fecha de actualización: <strong>9 de diciembre de 2021</strong></p>
        </div>
      </div>
      <div class="allies">
        <div class="container">
          <div class="row mb-4">
            <div class="col-6 col-sm-2 mb-4">
              <p class="redca">Red Centroamericana de Personas con VIH (Redca)</p>
            </div>
            <div class="col-6 col-sm-2">
              <p class="redla">Red Latinoamericana de Personas con VIH (Redla)</p>
            </div>
            <div class="col-6 col-sm-2">
              <p class="icwlatina">ICW Latina</p>
            </div>
            <div class="col-6 col-sm-2">
              <p class="jlac">Jóvenes Positivos</p>
            </div>
            <div class="col-6 col-sm-2">
              <p class="itpc">ITPC-LATCA</p>
            </div>
            <div class="col-6 col-sm-2">
              <p class="movimiento">Movimiento Latinoamericao y del Caribe de Mujeres Positivas</p>
            </div>
            <div class="col-6 col-sm-2 offset-sm-2">
              <p class="plaperts">Plaperts</p>
            </div>
            <div class="col-6 col-sm-2">
              <p class="gaylatino">Gay latino</p>
            </div>
            <div class="col-6 col-sm-2">
              <p class="redlactrans">Red LACTRANS</p>
            </div>
            <div class="col-6 col-sm-2">
              <p class="lanpud">Lanpud</p>
            </div>
            
          </div>

          <div class="row mt-4 pt-4">
            <div class="col-12 mt-4">
            <p class="text-center">Con el apoyo de</p>
            </div>
          </div>

          <div class="row mb-4 pb-4">
            <div class="col-6 col-sm-2 col-sm-offset-4">
              <a class="hivos" href="https://america-latina.hivos.org/">Hivos</a>
            </div>

            <div class="col-6 col-sm-2">
              <a class="onusida" href="https://www.unaids.org/es">Onusida</a>
            </div>
          </div>
        
        </div>
      </div>
        <div class="author">
          <p class="text-center">Forjado artesanalmente por<br>
            <a href="https://gobiernofacil.com" class="gobiernofacil">Gobierno Fácil</a>
          </p>
        </div>
    </footer>
  </div>
</template>