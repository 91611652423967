<script>
/**
 * DEPENDENCIES
 */
import {useStore} from "vuex";
import * as turf from "@turf/turf";
const d3 = Object.assign({}, require('d3-selection'), require('d3-geo')); 



/**
 * CONSTS
 */
const scale     = 400;
const multiply  = 10;

/**
 * METHODS
 */
const getDimensions = map => {
  let bbox = turf.bbox(map),
  w    = bbox[2] - bbox[0],
  h    = bbox[3] - bbox[1],
  res = [Math.abs(w) * multiply, Math.abs(h) * multiply];
  return res;
}

export default {
  props : ['country'],

  setup(props){
    const store       = useStore();
    const map         = store.state.maps.features.find(d => d.properties.iso_a3 == props.country);
    const dim         = getDimensions(map);
    const center      = turf.center(map).geometry.coordinates;
     const projection = d3.geoMercator().translate([dim[0]/2, dim[1]/2]).scale(scale).center(center);
    const path        = d3.geoPath().projection(projection);
    
    return {
      path, 
      map,
      dim
    }
  }
}
</script>

<template>
  <div>
    <div class="single-map">
      <svg preserveAspectRatio="xMinYMin meet" width="100%" height="100%" :viewBox="`0 0 ${dim[0]} ${dim[1]}`">
        <path :d="path(map)" stoke="#e0cabc" fill="#f0e4dd" stroke-width="1" />
      </svg>
    </div>
  </div>
</template>