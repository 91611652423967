<script>
import {computed} from "vue";
import {useStore} from "vuex";
import SvgIcon from '@/components/SvgIcon';
import SingleMap from "@/components/SingleMap";

export default {
  props : ['country', 'year'],
  setup(props){
    const currentYear =  process.env.VUE_APP_YEAR || 2020;
    const store     = useStore();
    const items     = computed( () => store.getters.getCountryIndices(props.country)); 
    const spending  = computed( () => store.getters.getValueFromIndexWithCountryAndYear('gasto_en_vih', props.country.id, currentYear));
    const percapita = computed( () => store.getters.getValueFromIndexWithCountryAndYear('per_capita', props.country.id, currentYear));
    
    const first90   = computed( () => store.getters.getValueFromIndexWithCountryAndYear('personas_que_viven_con_vih_que_conocen_su_estado', props.country.id, currentYear));
    const second90  = computed( () => store.getters.getValueFromIndexWithCountryAndYear('porcentaje_de_personas_que_viven_con_vih_que_reciben_tarv', props.country.id, currentYear));
    const third90   = computed( () => store.getters.getValueFromIndexWithCountryAndYear('porcentaje_de_personas_que_viven_con_vih_que_tienen_cargas_virales_suprimidas', props.country.id, currentYear));

    const text = computed( () => store.getters.getShortText(props.country.id));
    return {
      getIcon : store.getters.getIcon,
      items,
      spending,
      percapita,
      first90,
      second90,
      third90,
      format : store.getters.formatNumber,
      color  : store.getters.getColor,
      text
    }
  },
  components :{
    SvgIcon,
    SingleMap
  }
}
</script>
<template>
  <!-- country-->
        <div class="row">
          <div class="col-sm-9">
            <!-- title-->
            <h3 class="mb-4"> <router-link :to="{ name: 'Situation', params: { country: country.url}}" class="od_link subtitle">{{country.name}} </router-link></h3>
            <p>{{text}}</p>
            <div class="row">
              <div class="col-sm-6">
                <div class="ip_separator bottom titles"></div>
                <p class="im_label mb-0">
                  <svg-icon
                    :icon="getIcon('personas')"
                    :hasFill="true"
                    class="od_svg_icon icon_title"
                  ></svg-icon>
                  GASTO PER CAPITA EN VIH
                </p>
                <h4 v-if="percapita"><span class="im_amount sm">${{format(percapita)}}</span> USD</h4>
                <p v-else class="od_danger">Sin información</p>
              </div>
              <div class="col-sm-6">
                <div class="ip_separator bottom titles"></div>
                <p class="im_label mb-0">
                  <svg-icon
                    :icon="getIcon('gasto')"
                    :hasFill="true"
                    class="od_svg_icon icon_title"
                  ></svg-icon>
                  GASTO EN VIH
                </p>
                <h4 v-if="spending"><span class="im_amount sm">${{format(spending)}}</span> USD</h4>
                <p v-else class="od_danger">Sin información</p>
              </div>
            </div>
          </div>
          <!-- semáforo / mapa -->
          <div class="col-sm-3">
            <div class="row">
              <div class="col-4">
                <p class="mb-0 text-center im_label">90 <span></span></p>
                <!-- <div class="od_colorguide"></div> -->
                <div :class="color('personas_que_viven_con_vih_que_conocen_su_estado', first90)">{{first90 != null ? `${first90}%` : ''}}</div>
              </div>
              <div class="col-4">
                <p class="mb-0 text-center im_label">90 <span></span></p>
                <!-- <div class="od_colorguide _75">60%</div> -->
                <div :class="color('porcentaje_de_personas_que_viven_con_vih_que_reciben_tarv', second90)">{{second90 != null ? `${second90}%` : ''}}</div>
              </div>
              <div class="col-4">
                <p class="mb-0 text-center im_label">90 <span></span></p>
                <div :class="color('porcentaje_de_personas_que_viven_con_vih_que_tienen_cargas_virales_suprimidas', third90)">{{third90 != null ? `${third90}%` : ''}}</div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-8 offset-sm-2">
                <single-map :country="country.id" />
              </div>
            </div>
            <router-link :to="{ name: 'Situation', params: { country: country.url}}" class="od_dl_btn block">
            <!-- <router-link :to="{ name: 'Country', params: { country: country.url }}" class="od_dl_btn block"> -->
              Ir a {{country.name}} &#x2192;
            </router-link>
          </div>
        </div>
        <div class="row mt-4 pt-4">
          <div class="col-12 mt-4 pt-4">
            <div class="ip_separator bottom"></div>
          </div>
        </div>
        <!-- country ends-->
</template>