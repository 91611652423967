import { csv, json } from "d3-fetch";
import axios from "axios";
import countries from "@/assets/countries.csv";
const server = process.env.VUE_APP_SERVER;
const indicesURL = '/js/data/indices.csv';
const publicationsURL = '/js/data/publicaciones.csv';
const contractsURL = id => `/js/data/contracts/${id}_contracts.json`
const textIndices = ['país'];

const actions = {
  /**
    *  ACTIONS 
    * --------------------------------------------------------------------------------
    */

  /**
    * Load indices from CSV
    * @param {*} param0 
    */
  getIndicesFromCSV({ commit }) {
    csv(indicesURL).then(res => {
      parseIndicesFromStringToNumber(res);
      commit('saveIndicesToState', res);
    })
  },

  /**
   * Load publications from CSV
   * @param {*} param0 
   */
  getPublicationsFromCSV({ commit }) {
    csv(publicationsURL).then(res => {
      commit('savePublicationsToState', res);
    })
  },

  /**
   * Load contracts from multiple CSV
   * @param {*} param0 
   */
  loadContractsFromCSV({ commit }) {
    let countrieswc = countries.filter(d => d.contracts);
    for (let country of countrieswc) {
      let url = contractsURL(country.id);
      json(url).then(d => {
        commit('saveContractsToState', {
          country: country.id,
          contracts: d
        })
      }).catch(() => {
        commit('saveContractsToState', {
          country: country.id,
          contracts: null
        })
      })
    }
  },
  /**
   * ADMIN LOGIN 
   * --------------------------------------------------------------------------------
   */
  login({ commit }, credentials) {
    return new Promise((resolve, reject) => {
      axios.post(`${server}/login`, {
        email: credentials.email,
        password: credentials.password
      })
        .then(res => {
          commit('saveLoginInfo', res.data);
          return resolve(res.data);
        })
        .catch(e => {
          return reject(e);
        });
    })
  },

  logout({ commit, state }) {
    return new Promise((resolve, reject) => {
      axios.post(`${server}/logout`, {}, {
        headers: {
          Authorization: "Bearer " + state.token
        }
      })
        .then(res => {
          commit("removeLoginInfo");
          resolve(res);
        })
        .catch(e => {
          commit("removeLoginInfo");
          reject(e)
        })
    });
  },

  validateSession(/*{state}*/) {
    return new Promise((resolve/*, reject*/) => {
      return resolve(true);
    });
  },
  /**
   * ADMIN USERS 
   * --------------------------------------------------------------------------------
   */
  getUsers({state, commit}) {
    return new Promise((resolve, reject) => {
      axios.get(`${server}/users`, {
        headers: {
          Authorization: "Bearer " + state.token
        }
      })
        .then(res => {
          commit("createUsers", res.data.results);
          resolve(res);
        })
        .catch(e => {
          reject(e)
        });
    });
  },

  getUser({state}, id) {
    return new Promise((resolve, reject) => {
      axios.get(`${server}/user/${id}`, {
        headers: {
          Authorization: "Bearer " + state.token
        }
      })
        .then(res => {
          resolve(res);
        })
        .catch(e => {
          reject(e)
        });
    });
  },

  updatePassword({ state }, password) {
    return new Promise((resolve, reject) => {
      axios.post(`${server}/update-password`, {password}, {
        headers: {
          Authorization: "Bearer " + state.token
        }
      })
        .then(res => {
          resolve(res);
        })
        .catch(e => {
          reject(e)
        });
    });
  },

  saveUser({ state/*, commit*/ }, data) {
    return new Promise((resolve, reject) => {
      axios.post(`${server}/save-user`, data, {
        headers: {
          Authorization: "Bearer " + state.token
          // 'Content-Type': 'multipart/form-data'
        }
      })
        .then(res => {
          // commit("saveUserToState", res.data.newitem);
          resolve(res);
        })
        .catch(e => {
          reject(e)
        })
    });
  },

  updateUser({ state/*, commit */ }, data) {
    return new Promise((resolve, reject) => {
      axios.post(`${server}/update-user`, data, {
        headers: {
          Authorization: "Bearer " + state.token
          // 'Content-Type': 'multipart/form-data'
        }
      })
        .then(res => {
          // commit("updateUserToState", res.data.item);
          resolve(res);
        })
        .catch(e => {
          reject(e)
        })
    });
  },

  deleteUser({ state /*, commit */}, id) {
    return new Promise((resolve, reject) => {
      axios.post(`${server}/delete-user`, {id}, {
        headers: {
          Authorization: "Bearer " + state.token
        }
      })
        .then(res => {
          // commit("deleteUserFromState", res.data);
          resolve(res);
        })
        .catch(e => {
          reject(e)
        })
    });
  },

  /**
   * ADMIN INDICES 
   * --------------------------------------------------------------------------------
   */
  saveIndices({ state }, indices) {
    return new Promise((resolve, reject) => {
      axios.post(`${server}/update-indices`, indices, {
        headers: {
          Authorization: "Bearer " + state.token
        }
      })
        .then(res => {
          resolve(res);
        })
        .catch(e => {
          reject(e)
        });
    });
  },

  /**
   * ADMIN PUBLICATIONS 
   * --------------------------------------------------------------------------------
   */
  savePublication({ state, commit }, data) {
    return new Promise((resolve, reject) => {
      axios.post(`${server}/save-publication`, data, {
        headers: {
          Authorization: "Bearer " + state.token,
          'Content-Type': 'multipart/form-data'
        }
      })
        .then(res => {
          commit("savePublicationToState", res.data.newitem);
          resolve(res);
        })
        .catch(e => {
          reject(e)
        })
    });
  },

  updatePublication({ state, commit }, data) {
    return new Promise((resolve, reject) => {
      axios.post(`${server}/update-publication`, data, {
        headers: {
          Authorization: "Bearer " + state.token,
          'Content-Type': 'multipart/form-data'
        }
      })
        .then(res => {
          commit("updatePublicationToState", res.data.item);
          resolve(res);
        })
        .catch(e => {
          reject(e)
        })
    });
  },

  deletePublication({ state, commit }, id) {
    return new Promise((resolve, reject) => {
      axios.post(`${server}/delete-publication`, {id}, {
        headers: {
          Authorization: "Bearer " + state.token
        }
      })
        .then(res => {
          commit("deletePublicationFromState", res.data);
          resolve(res);
        })
        .catch(e => {
          reject(e)
        })
    });
  },

  /**
   * ADMIN CONTRACTS 
   * --------------------------------------------------------------------------------
   */
  saveContract({ state, commit }, data) {
    return new Promise((resolve, reject) => {
      axios.post(`${server}/save-contract`, data, {
        headers: {
          Authorization: "Bearer " + state.token,
          'Content-Type': 'multipart/form-data'
        }
      })
        .then(res => {
          commit("saveContractToState", res.data.newItem);
          resolve(res);
        })
        .catch(e => {
          reject(e)
        })
    });
  },

  updateContract({ state , commit }, data) {
    return new Promise((resolve, reject) => {
      axios.post(`${server}/update-contract`, data, {
        headers: {
          Authorization: "Bearer " + state.token,
          'Content-Type': 'multipart/form-data'
        }
      })
        .then(res => {
          commit("updateContractToState", res.data.item);
          resolve(res);
        })
        .catch(e => {
          reject(e)
        })
    });
  },

  deleteContract({ state, commit }, data) {
    return new Promise((resolve, reject) => {
      axios.post(`${server}/delete-contract`, data, {
        headers: {
          Authorization: "Bearer " + state.token
        }
      })
        .then(res => {
          commit("deleteContractFromState", res.data);
          resolve(res);
        })
        .catch(e => {
          reject(e)
        })
    });
  }
};

export default actions;

/**
* HELPERS
* --------------------------------------------------------------------------------
*/

/**
 * Convert every index from string to number, if the data comes from CSV
 * @param {Array} indices 
 * @returns Array
 */
const parseIndicesFromStringToNumber = indices => {
  const keys = Object.keys(indices[0]);
  for (let indice of textIndices.concat(keys.filter(d => d.indexOf('_moneda') != -1))) {
    let pos = keys.indexOf(indice);
    keys.splice(pos, 1);
  }
  for (let indice of indices) {
    for (let key of keys) {
      indice[key] = indice[key] ? +indice[key] : null;
    }
  }
  return indices;
}