<script>
import {scaleLinear} from "d3-scale";
/**
 * CONST
 */
const width        = 500;
const height       = 500;
const leftMargin   = 20;
const leftText     = 50;
const bottomMargin = 20;
const bottomText   = 50;
const topMargin    = 50;
const rightMargin   = 20;
const guideCenterX = leftText + leftMargin + (width/2);
const guideCenterY = topMargin + (height/2);
const guideX1      = leftText;
const guidex2      = width + leftMargin + leftText + rightMargin;
const xDomain      = [-80,80];
const yDomain      = [10, -10];
const xRange       = [leftMargin + leftText, leftMargin + leftText + width];
const yRange       = [topMargin, topMargin + height];
const xScale       = scaleLinear().domain(xDomain).range(xRange);
const yScale       = scaleLinear().domain(yDomain).range(yRange);
const xTicks       = xScale.ticks(9);
const yTicks       = yScale.ticks(13);

const colors       = {
  BOL : "#E8862D",
  COL : "#3E94CE",
  CRI : "#744795",
  ECU : "#3BB3B5",
  SLV : "#E8421D",
  GTM : "#E5D82E",
  HND : "#5e005a",
  NIC : "#A0C43D",
  PAN : "#9F0000",
  PRY : "#DD4EDD",
  PER : "#0F69BA"
}

const colorKeys = Object.keys(colors);

export default {
  props : ['vectors'],
  setup(){
    return{
      width,
      height,
      leftMargin,
      leftText,
      bottomMargin,
      topMargin,
      rightMargin,
      bottomText,
      guideCenterX,
      guideCenterY,
      guideX1,
      guidex2,
      xScale,
      yScale,
      xTicks,
      yTicks,
      colors,
      colorKeys
    }
  }
}
</script>

<template>
  <svg preserveAspectRatio="xMinYMin meet"
       width="100%"
       height="100%"
       :viewBox="`0 0 ${guidex2} ${height + bottomMargin + topMargin + bottomText}`">
  <defs>
    <!-- arrowhead marker definition -->
    <marker v-for="color of colorKeys" :id="color" viewBox="0 0 10 10" refX="5" refY="5"
        markerWidth="3" markerHeight="3"
        :fill="colors[color]"
        orient="auto-start-reverse" :key="`color-${color}`">
      <path d="M 0 0 L 10 5 L 0 10 z" />
    </marker>

    <!-- simple dot marker definition -->
    <marker id="dot" viewBox="0 0 10 10" refX="5" refY="5"
        markerWidth="5" markerHeight="5">
      <circle cx="5" cy="5" r="5" fill="red" />
    </marker>
  </defs>

  <!-- draw axis guides -->
    <g class="axis-guides">
      <line v-for="(x, i) of xTicks" 
            :x1="xScale(x)" 
            y1="0" 
            :x2="xScale(x)" 
            :y2="height + topMargin + bottomMargin" 
            :stroke="i == 4 ? '' : 'rgba(0,0,0,.05)'" 
            :key="'guide-x-' + x" />
      <line v-for="(y, i) of yTicks" 
            :x1="guideX1" 
            :y1="yScale(y)" 
            :x2="guidex2" 
            :y2="yScale(y)" 
            :stroke="i == 6 ? '' : 'rgba(0,0,0,.05)'"  
            :key="'guide-y-' + y" />
    </g>
  <!-- draw axis lines 
    <g class="axis-lines">
      <line :x1="leftMargin + leftText" y1="0" :x2="leftMargin + leftText" :y2="height + topMargin + bottomMargin" stroke="blue" />
      <line :x1="guideX1" :y1="topMargin + height" :x2="guidex2" :y2="topMargin + height" stroke="blue" />
    </g>-->
  <!-- draw center guides --> 
  <g class="axis-center-lines">
    <line :x1="guideCenterX" y1="0" :x2="guideCenterX" :y2="height + topMargin + bottomMargin" stroke="rgba(0,0,0,0.7)" stroke-dasharray="5,5" />
    <line :x1="guideX1" :y1="guideCenterY" :x2="guidex2" :y2="guideCenterY" stroke="rgba(0,0,0,0.7)" stroke-dasharray="5,5" />
  </g>
  <!-- draw x axis num -->
  <g class="axis-x-ticks">
    <text v-for="x of xTicks" 
          :x="xScale(x)" 
          :y="topMargin + height + bottomMargin"
          alignment-baseline="hanging"
          text-anchor="middle" 
           class="sg_text" 
          :key="'tick-x-' + x">{{x}}</text>
  </g>

  <!-- draw y axis num -->
  <g class="axis-y-ticks">
    <text v-for="y of yTicks" 
          :x="leftText" 
          :y="yScale(y)"
          alignment-baseline="middle"
          text-anchor="end"
          class="sg_text" 
          :key="'tick-y-' + y">{{y}}</text>
  </g>

  <!--- 
  //////
  //////
  //////
  //////  aquí van los 4 textos que aparecen en cada cuadrante 
  //////
  //////
  //////
  //////
  //////
  -->
  <!-- draw legend: Disminución del Gasto-->
  <g class="text-1">
    <text :x="leftText + 22"
        :y="topMargin + height - 12"
        class="sg_text graph">Disminución del Gasto</text>
  </g>
  <!-- draw legend: Disminución de la Incidencia de VIH-->
  <g class="text-1">
    <text :x="leftText + 22"
          :y="topMargin + height"
        class="sg_text graph">Disminución de la Incidencia de VIH</text>
  </g>

  <!-- draw legend: Disminución del Gasto-->
  <g class="text-1">
    <text :x="leftText + 22"
        :y="12"
        class="sg_text graph">Disminución del Gasto</text>
  </g>
  <!-- draw legend: Aumento de la Incidencia de VIH-->
  <g class="text-1">
    <text :x="leftText + 22"
          :y="24"
        class="sg_text graph">Aumento de la Incidencia de VIH</text>
  </g>

  <!-- draw legend: Aumento del Gasto-->
  <g class="text-1">
    <text :x="width"
        :y="12"
        class="sg_text graph">Aumento del Gasto</text>
  </g>
  <!-- draw legend: Aumento de la Incidencia de VIH-->
  <g class="text-1">
    <text :x="width-61"
          :y="24"
        class="sg_text graph">Aumento de la Incidencia de VIH</text>
  </g>


  <!-- draw legend: Aumento del Gasto-->
  <g class="text-1">
    <text :x="width"
        :y="topMargin + height - 12"
        class="sg_text graph">Aumento del Gasto</text>
  </g>
  <!-- draw legend: Disminución de la Incidencia de VIH-->
  <g class="text-1">
    <text :x="width-77"
          :y="topMargin + height"
        class="sg_text graph">Disminución de la Incidencia de VIH</text>
  </g>

  <!--- 
  //////
  //////
  //////
  //////  aquí terminan los 4 textos que aparecen en cada cuadrante 
  //////
  //////
  //////
  //////
  //////
  -->


  <!-- draw vectors -->
  <g class="country-vectors" fill="red">
    <line v-for="vector of vectors" 
          :x1="xScale(0)" 
          :y1="yScale(0)" 
          :x2="xScale(vector.gasto)" 
          :y2="yScale(vector.incidencia)" 
          :stroke="colors[vector.country]" 
          :fill="colors[vector.country]" 
          stroke-width="2.5"
          :marker-end="`url(#${vector.country})`"
          :key="`vector-${vector.country}`" />
  </g>
  </svg>
</template>