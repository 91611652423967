<script>
/**
 * DEPENDENCIES
 */
import {computed, ref} from "vue";
import {useStore} from "vuex";
import {mean} from "d3-array";
import MainMap from "@/components/MainMap.vue"



/**
 * CONSTANTS
 */
const retroviralesIndex = 'porcentaje_de_personas_que_viven_con_vih_que_conocen_su_estado_serológico_y_reciben_tarv';
const _year = ref(null)



/**
 * COMPUTED
 */
const years = store => computed( () => {
    return store.getters.getAvaliableYearsFromIndex(retroviralesIndex);
});

const year = years => computed( () => {
  if(!years.value) return null;
  if(!_year.value) return years.value[0];
  return _year.value;
});

const values = (store, year) => computed( () => {
  return store.getters.getCountriesIndiceByYear(retroviralesIndex, year.value)
});



/**
 * METHODS
 */
const updateYear = e => {
  _year.value = +e.target.value;
}


/**
 * VUE COMPONENT
 */
export default {
  components: { MainMap },
  setup(){
    const store    = useStore();
    const yearList =  years(store);
    const _year    = year(yearList);
    const _values  = values(store, _year);
    //const mean = getMean(store.getters.format);
    const _mean = computed( () => {
      if(!_values.value) return null;
      return mean(_values.value.map(d => d.value))
    });


    return {
      year : _year,
      years : yearList,
      updateYear,
      values : _values,
      mean : _mean,
      format : store.getters.formatNumber
    }
  }
}
</script>

<template>
  <div class="im_main_map">
      <div class="container">
        <div class="row">
          <div class="col-sm-2 mt-4 pt-4">
            <div class="im_box pb-0 pt-1">
              <p>Año:
                <select v-model="year" class="od_select_home" v-on:change="updateYear">
                  <option v-for="(y,i) in years" :key="`y-${i}`">{{y}}</option>
                </select>
              </p>
            </div>
          </div>
          <div class="col-sm-6 col-lg-6">
            <main-map v-if="values" :values="values" />
          </div>
          <div class="col-sm-4 col-lg-4 mt-4 pt-4" v-if="values">
            <div class="im_box">
              <p>
                <span class="im_amount bg block">{{format(mean)}}%</span>
                <span class="im_subtitle"><strong>de las personas con VIH y que conocen su estado serológico reciben TARV</strong>,
                <span :class='90  > mean ? "od_danger": "" '>{{ 90  > mean ? format(90 - mean) + '% por debajo' : format(mean - 90)  + '% por encima'  }}</span>* del 90% del compromiso 1 de los objetivos 90-90-90 en los países que integran <strong>Observadatos</strong>
                (<span v-for="(item, i) in values" :key="`country-${i}`">{{i+1 == 11 ? ' y ' : ''}} {{item.name}}{{i+1 == 11 ? '' : ','}} </span>)</span></p>
            </div>
            <p class="text-right od_note mt-2">*En promedio en los {{values.length}} países con un estimado de {{labelSum}} personas con VIH en {{year}} de acuerdo con ONUSIDA</p>
          </div>
        </div>
      </div>
    </div>
</template>